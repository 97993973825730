import React from "react";

import {
  ArticlesDisplay,
  BrandOrRangeDisplay,
  CareersList,
  JotFormBlock,
  LeftRightBlock,
  MediaCarousel,
  ReviewsBlock,
  TextCta,
  TextSlider,
  WhereToBuyList,
} from "@sections";

import {
  isSanityArticlesDisplay,
  isSanityBlockContentWithMargin,
  isSanityBrandOrRangeList,
  isSanityCareersList,
  isSanityJotFormBlock,
  isSanityLeftRightBlock,
  isSanityMediaCarousel,
  isSanityReviewsBlock,
  isSanityTextCta,
  isSanityTextSlider,
  isSanityWhereToBuyList,
} from "@util/types";
import { BlocksContent } from "@global";
import { StandardLayoutContainer } from "@util/standard";

interface Props {
  data: any;
  extraData?: any;
}

export default function Sections({ data, extraData }: Props) {
  if (data == null) return null;
  const { marginBottom, _key } = data;
  if (_key == null) return null;

  const sectionAttributes = {
    className: `${isSanityBrandOrRangeList(data) ? "" : "snap-align-start"} ${
      marginBottom ? `mb-${marginBottom}` : `mb-none`
    }`,
    id: _key && `section-id-${_key}`,
  };

  return (
    <section {...sectionAttributes}>
      <Section data={data} extraData={extraData} />
    </section>
  );
}

const Section = ({ data, extraData }: Props) => {
  const content = { data, extraData };

  if (isSanityTextCta(data)) {
    return <TextCta {...content} />;
  }

  if (isSanityLeftRightBlock(data)) {
    return <LeftRightBlock {...content} />;
  }

  if (isSanityBrandOrRangeList(data)) {
    return <BrandOrRangeDisplay {...content} />;
  }

  if (isSanityMediaCarousel(data)) {
    return <MediaCarousel {...content} />;
  }

  if (isSanityWhereToBuyList(data)) {
    return <WhereToBuyList {...content} />;
  }

  if (isSanityCareersList(data)) {
    return <CareersList />;
  }

  if (isSanityReviewsBlock(data)) {
    return <ReviewsBlock data={data} />;
  }

  if (isSanityArticlesDisplay(data)) {
    return <ArticlesDisplay data={data} />;
  }
  if (isSanityTextSlider(data)) {
    return <TextSlider data={data} />;
  }

  if (isSanityJotFormBlock(data)) {
    return <JotFormBlock data={data} />;
  }

  if (isSanityBlockContentWithMargin(data)) {
    if (data.text == null) return null;
    return (
      <StandardLayoutContainer margin="auto">
        <BlocksContent data={data.text} />
      </StandardLayoutContainer>
    );
  }
  return <h3>Error...</h3>;
};
