import * as React from "react";
import { graphql, PageProps } from "gatsby";

import { Query } from "@graphql-types";
import { useStore } from "@util/states";
import Layout from "@shared/layout";
import { Hero } from "@global";
import Sections from "@shared/sections";

interface Props extends PageProps {
  data: Query;
}

const NotFoundPage = ({ data }: Props) => {
  if (data.allSanityPageNotFound == null) return null;

  const { currentRegion } = useStore();
  const filteredRegionData = data.allSanityPageNotFound.nodes.filter(page => {
    if (page.region == null) return null;
    if (page.region.iban === currentRegion.iban) return page;
    if (page.region.iban === process.env.GATSBY_DEFAULT_IBAN_REGION) return page;

    return null;
  });

  if (filteredRegionData[0] == null) return null;

  const regionSpecificData =
    filteredRegionData.length > 1
      ? filteredRegionData.find(item => item?.region?.iban === currentRegion.iban)
      : filteredRegionData[0];

  if (regionSpecificData == null) return null;

  const { hero, sections } = regionSpecificData;

  return (
    <Layout notFound>
      <Hero data={hero} />
      {sections && sections.map(section => <Sections key={section?._key} data={section} />)}
    </Layout>
  );
};

export default NotFoundPage;

export const query = graphql`
  {
    allSanityPageNotFound {
      nodes {
        _id
        _type
        sections {
          ...sanitySections
        }
        hero {
          ...sanityHero
        }
        region {
          ...sanityRegion
        }
      }
    }
  }
`;
